import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase-config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { MdDeleteForever, MdAddCircle } from "react-icons/md";
import { BsFillPencilFill } from "react-icons/bs";

const EinstellungenFunkkennungen = (props) => {
  const [funkkennungen, setFunkkennungen] = useState([]);
  const [funkkennungNeu, setFunkkennungNeu] = useState("");
  const [modal, setModal] = useState(false);
  const [fahrzeugEmail, setFahrzeugEmail] = useState("");
  const [funkkennungId, setFunkkennungId] = useState("");

  const funkkennungenRef = collection(db, "funkkennungen");

  useEffect(() => {
    const queryFunkkennungen = query(funkkennungenRef, orderBy("bezeichnung"));

    const unsubscribe = onSnapshot(queryFunkkennungen, (snapshot) => {
      let funkkennungen = [];
      snapshot.forEach((doc) => {
        funkkennungen.push({ ...doc.data(), id: doc.id });
      });
      setFunkkennungen(funkkennungen);
    });

    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    setModal(false);
    setFunkkennungNeu("");
    setFahrzeugEmail("");
    setFunkkennungId("");
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleEdit = (funkkennung) => {
    // console.log('handleEdit', fahrzeug);
    setFunkkennungNeu(funkkennung.bezeichnung);
    setFahrzeugEmail(funkkennung.kontaktEmail);
    setFunkkennungId(funkkennung.id);

    setModal(true);
  };

  const handleSave = () => {
    const data = {
      bezeichnung: funkkennungNeu,
      inUse: false,
      kontaktEmail: fahrzeugEmail,
    };

    // Kennung neu anlegen oder bestehende Anpassen

    if (funkkennungId === "") {
      addDoc(funkkennungenRef, data)
        .then((docRef) => {
          handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const dataUpdate = {
        bezeichnung: funkkennungNeu,
        kontaktEmail: fahrzeugEmail,
      };
      data.id = funkkennungId;
      // console.log('data update', dataUpdate);

      const docRef = doc(db, "funkkennungen", data.id);

      setDoc(docRef, dataUpdate, { merge: true })
        .then((docRef) => {
          // console.log("Document Field has been updated successfully");
          handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDelete = (id) => {
    // console.log('delete clicked', id);
    const docRef = doc(db, "funkkennungen", id);
    deleteDoc(docRef)
      .then(() => {
        // console.log('Eintrag gelöscht');
      })
      .catch((error) => {
        console.log("error");
      });
  };

  let color = "black";
  if (props.color !== undefined) color = props.color;
  return (
    <>
      <Card className="statusColumn">
        <Card.Header
          style={{
            backgroundColor: props.bg,
            color: color,
            textAlign: "center",
          }}
        >
          <div className="settingHeader">
            <div className="settingTitle">{props.title}</div>
            <div>
              <Button
                variant="success"
                className="btn-add"
                onClick={handleOpen}
              >
                <MdAddCircle />
              </Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Bezeichnung</th>
                <th>Verwendet</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {funkkennungen.map((funkkennung) => {
                return (
                  <tr key={funkkennung.id}>
                    <td>{funkkennung.bezeichnung}</td>
                    <td>{funkkennung.inUse ? "JA" : "NEIN"}</td>
                    <td>
                      <BsFillPencilFill
                        className="btn-edit"
                        onClick={() => {
                          handleEdit(funkkennung);
                        }}
                      />
                      <MdDeleteForever
                        className="btn-delete"
                        onClick={() => {
                          handleDelete(funkkennung.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Funkkennung hinzufügen</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Bezeichnung</Form.Label>
            <Form.Control
              type="text"
              value={funkkennungNeu}
              onChange={(event) => setFunkkennungNeu(event.target.value)}
              placeholder="Bezeichnung eingeben"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Fahrzeug Kontakt E-Mail</Form.Label>
            <Form.Control
              type="text"
              value={fahrzeugEmail}
              onChange={(event) => setFahrzeugEmail(event.target.value)}
              placeholder="E-Mail Adresse"
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EinstellungenFunkkennungen;
