import React, { useEffect, useState } from "react";
import { db } from "../config/firebase-config";
import { addDoc, collection } from "firebase/firestore";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import axios from "axios";

const ModalMangel = (props) => {
  const today = new Date();

  const [kategorie, setKategorie] = useState("");
  const [kontaktVorname, setKontaktVorname] = useState("");
  const [kontaktNachname, setKontaktNachname] = useState("");
  const [kontaktEmail, setKontaktEmail] = useState("");
  const [kontaktTelefon, setKontaktTelefon] = useState("");
  const [kilometer, setKilometer] = useState("");
  const [beschreibung, setBeschreibung] = useState("");
  const [fahrzeugId, setFahrzeugId] = useState("");
  const [kennzeichenList, setKennzeichenList] = useState([]);
  const [cannotSave, setCannotSave] = useState(true);
  const [checkDatenschutz, setCheckDatenschutz] = useState(false);
  const [kennungEmail, setKennungEmail] = useState("");
  const [kennzeichen, setKennzeichen] = useState("");
  const [kennung, setKennung] = useState("");

  useEffect(() => {
    let tmp = [];
    for (let i = 0; i < props.fahrzeuge.length; i++) {
      tmp.push({
        kennzeichen: props.fahrzeuge[i].kennzeichen,
        id: props.fahrzeuge[i].id,
      });
    }

    tmp.sort((a, b) =>
      a.kennzeichen > b.kennzeichen ? 1 : b.kennzeichen > a.kennzeichen ? -1 : 0
    );

    setKennzeichenList(tmp);
  }, [props.fahrzeuge]);

  useEffect(() => {
    let kennungTmp = "";

    for (let i = 0; i < props.fahrzeuge.length; i++) {
      if (props.fahrzeuge[i].id === fahrzeugId) {
        kennungTmp = props.fahrzeuge[i].funkkennung;
        setKennung(kennungTmp);
        setKennzeichen(props.fahrzeuge[i].kennzeichen);
      }
    }

    for (let i = 0; i < props.funkkennungen.length; i++) {
      if (props.funkkennungen[i].bezeichnung === kennungTmp) {
        setKennungEmail(props.funkkennungen[i].kontaktEmail);
      }
    }
  }, [fahrzeugId, props.funkkennungen, props.fahrzeuge]);

  useEffect(() => {
    if (
      kontaktVorname !== "" &&
      kontaktNachname !== "" &&
      fahrzeugId !== "" &&
      kilometer !== "" &&
      kontaktEmail !== "" &&
      kategorie !== "" &&
      beschreibung !== "" &&
      checkDatenschutz === true
    ) {
      setCannotSave(false);
    } else {
      setCannotSave(true);
    }
  }, [
    kontaktVorname,
    kontaktNachname,
    fahrzeugId,
    kilometer,
    kontaktEmail,
    kategorie,
    beschreibung,
    checkDatenschutz,
  ]);

  useEffect(() => {
    if (fahrzeugId !== "") {
      setCannotSave(false);
    } else setCannotSave(true);
  }, [fahrzeugId]);

  const changeKm = (e) => {
    let valueTmp = e.target.value;

    const numericString = valueTmp.replace(/\D/g, "");

    // Wenn der String leer ist, gib eine leere Zeichenkette zurück
    if (numericString === "") return "";

    // Wandelt die Zahl in einen Integer um
    const number = parseInt(numericString, 10);

    setKilometer(number.toLocaleString("de-DE"));
  };

  const handleSave = () => {
    // console.log("save new Mangel mit id", fahrzeugId);

    const path = "fahrzeuge/" + fahrzeugId + "/maengel";
    const docRef = collection(db, path);
    // const docRef = doc(db, "fahrzeuge");

    let data = {
      new: true,
      status: "offen",
      datumAngelegt: today,
      datumLastUpdate: today,
      kategorie: kategorie,
      kontaktName: kontaktVorname + " " + kontaktNachname,
      kontaktEmail: kontaktEmail,
      kontaktTelefon: kontaktTelefon,
      priorität: 4,
      kilometer: kilometer,
      historie: [
        {
          beschreibung: beschreibung,
          nutzerkreis: "",
          datum: today,
        },
      ],
    };

    // Atomically add a new region to the "regions" array field.
    addDoc(docRef, data).then((doc) => {
      writeToProtokoll(doc.id);
      sendNotifyEmail(data);
    });

    handleClose();
  };

  const writeToProtokoll = (id) => {
    const path = "fahrzeuge/" + fahrzeugId + "/protokoll";
    const docRef = collection(db, path);

    let data = {
      status: "neu",
      kategorie: kategorie,
      kontaktName: kontaktVorname + " " + kontaktNachname,
      priorität: 4,
      kilometer: kilometer,
      beschreibung: beschreibung,
      nutzerkreis: "",
      datum: today,
      mangelId: id,
    };

    // Atomically add a new region to the "regions" array field.
    addDoc(docRef, data);
  };

  const handleClose = () => {
    setFahrzeugId("");
    props.close();
  };

  const prioToText = (prio) => {
    switch (prio) {
      case "1":
      case 1:
        return "Hoch";
      case "2":
      case 2:
        return "Mittel";
      case "3":
      case 3:
        return "Niedrig";
      default:
        return "Ohne";
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendNotifyEmail = (data) => {
    let date = new Date();

    const localDate = date.toLocaleString().replace(" ", "");
    const datumSplit = localDate.split(",");
    const datum = datumSplit[0].split(".");

    const datumParse =
      datum[1] + "/" + datum[0] + "/" + datum[2] + " " + datumSplit[1];

    const emailData = {
      email: data.kontaktEmail,
      emailTo: "technik@rettungsdienstbremen.de",
      emailCC: kennungEmail,
      kennzeichen: kennzeichen,
      kennung: kennung,
      beschreibung: data.historie[0].beschreibung,
      kilometerstand: data.kilometer,
      timestamp: Date.parse(datumParse),
      telefon: data.kontaktTelefon,
      kontaktName: data.kontaktName,
      prio: prioToText(data.prio),
      kategorie: data.kategorie,
    };

    // const emailData = {
    //   email: data.kontaktEmail,
    //   emailTo: "helfrich@moinmoin-media.de",
    //   emailCC: kennungEmail,
    //   kennzeichen: kennzeichen,
    //   kennung: kennung,
    //   beschreibung: data.historie[0].beschreibung,
    //   kilometerstand: data.kilometer,
    //   timestamp: Date.parse(datumParse),
    //   telefon: data.kontaktTelefon,
    //   kontaktName: data.kontaktName,
    //   prio: prioToText(data.prio),
    //   kategorie: data.kategorie,
    // };

    const emailData_ext = {
      email: data.kontaktEmail,
      emailTo: data.kontaktEmail,
      emailCC: "",
      kennzeichen: kennzeichen,
      kennung: kennung,
      beschreibung: data.historie[0].beschreibung,
      kilometerstand: data.kilometer,
      timestamp: Date.parse(datumParse),
      telefon: data.kontaktTelefon,
      kontaktName: data.kontaktName,
      prio: prioToText(data.prio),
      kategorie: data.kategorie,
    };

    axios
      .post(
        "https://fleethq-email.osc-fr1.scalingo.io/api/fleetHQ/mangelNeu",
        emailData
      )
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        // console.log(error);
      });
    axios
      .post(
        "https://fleethq-email.osc-fr1.scalingo.io/api/fleetHQ/mangelNeu",
        emailData_ext
      )
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleCheckDatenschutz = (e) => {
    let result = false;
    if (e.target.value === false || e.target.value === "false") {
      result = true;
    } else {
      result = false;
    }

    setCheckDatenschutz(result);
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mangel an Fahrzeug melden</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kennzeichen</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      dataLiveSearch
                      onChange={(e) => {
                        setFahrzeugId(e.target.value);
                      }}
                    >
                      <option value={""}>Bitte wählen...</option>
                      {kennzeichenList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.kennzeichen}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kilometerstand</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputKilomter"
                      value={kilometer}
                      onChange={(e) => {
                        changeKm(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputVorname"
                      value={kontaktVorname}
                      onChange={(e) => {
                        setKontaktVorname(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputNachname"
                      value={kontaktNachname}
                      onChange={(e) => {
                        setKontaktNachname(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>E-Mail</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputEmail"
                      value={kontaktEmail}
                      onChange={(e) => {
                        setKontaktEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control
                      type="text"
                      id="inputTelefon"
                      value={kontaktTelefon}
                      onChange={(e) => {
                        setKontaktTelefon(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Kategorie des Mangels</Form.Label>
                    <Form.Select
                      name="modalstatus"
                      value={kategorie}
                      onChange={(e) => {
                        setKategorie(e.target.value);
                      }}
                    >
                      <option value="">Bitte wählen...</option>
                      {props.kategorien.map((kategorie) => {
                        return (
                          <option
                            value={kategorie.bezeichnung}
                            key={kategorie.id}
                          >
                            {kategorie.bezeichnung}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Mangelbeschreibung</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ height: "150px" }}
                      onChange={(e) => {
                        setBeschreibung(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    type="checkbox"
                    id={`default-checkbox`}
                    checked={checkDatenschutz}
                    value={checkDatenschutz}
                    onChange={(e) => {
                      handleCheckDatenschutz(e);
                    }}
                  />{" "}
                  Ich habe die{" "}
                  <a href="/datenschutz.html" target="_blank">
                    Datenschutzerklärung
                  </a>{" "}
                  gelesen und akzeptiert.
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={cannotSave}>
            Meldung abschicken
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalMangel;
